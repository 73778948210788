import React from "react"
import styled, { css } from "styled-components"
import { mainPad, mainPadCenter, mq } from "../../styles/mixins"
import theme from "../theme"
import { StaticImage } from "gatsby-plugin-image"
import Trademark from "../trademark"
import { Headline, Subhead } from "./snapshot"

export default function Figure() {
  return (
    <Wrapper>
      <TextWrapper>
        <Subhead>organize</Subhead>
        <Headline style={{maxWidth: 450}}>
          Unify your data to maximize its organizational value.
        </Headline>
      </TextWrapper>
      <ImageWrapper>
        <StaticImage
          quality={100}
          src="../../assets/product_flowchart.png"
          placeholder="none"
        />
      </ImageWrapper>
      <TextWrapper>
        <Text>
          Bring together every available data source and provide actionable
          insights to improve fan acquisition and growth, data valuation, and
          new revenue opportunities.
        </Text>
      </TextWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 48px;

  ${mq(">lg")} {
    margin-top: 80px;
  }
`

const ImageWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  
  ${mainPadCenter};

  ${mq(">lg")} {
    margin-top: -60px;
    margin-bottom: -60px;
  }
`

const TextWrapper = styled.div`
  ${mainPadCenter};
`

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.125;
  font-weight: ${theme.weights.light};
  margin-top: 12px;
  max-width: 450px;
`
