import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import { mainPad, mainPadCenter, mq } from "../../styles/mixins"
import theme from "../theme"

export default function Snapshot() {
  return (
    <Wrapper>
      <TextWrapper>
        <Subhead>value</Subhead>
        <Headline>What is your data worth?</Headline>
      </TextWrapper>
      <ImageWrapper>
        <StaticImage src="../../assets/value.snapshot.png" placeholder="blur" />
      </ImageWrapper>
    </Wrapper>
  )
}

export const Body = styled.p`
  font-size: 1rem;
  font-weight: ${theme.weights.light};
  line-height: 1.25;
  margin-top: 16px;
`

export const Subhead = styled.small`
  text-transform: uppercase;
  font-weight: ${theme.weights.black};
  font-size: 1.125rem;
  display: block;
  line-height: 1.777;
  letter-spacing: 0.125rem;
  color: ${theme.colors.blue["20"]};
  margin-bottom: 8px;
`

export const Headline = styled.h2`
  font-size: 2rem;
  letter-spacing: -0.03em;
  font-weight: ${theme.weights.bold};
  line-height: 1;
  width: 100%;
`

const Wrapper = styled.div``

const ImageWrapper = styled.div`
  ${mainPadCenter};
  margin-top: 32px;
`

const TextWrapper = styled.div`
  ${mainPadCenter};
`
