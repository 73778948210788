import { useState, useCallback } from "react"

export default function useInputState(initialState = "") {
  const [state, setState] = useState(initialState)

  const onChangeHandler = useCallback(
    e => {
      setState(e.target.value)
    },
    [setState]
  )

  return [state, onChangeHandler]
}
