import React, { useRef } from "react"
import styled from "styled-components"
import { mainPadCenter, pad } from "../../styles/mixins"
import theme from "../theme"
import { StaticImage } from "gatsby-plugin-image"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, A11y, Autoplay } from "swiper"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import "swiper/css/a11y"

import { Body } from "./snapshot"

export default function Carousel() {
  const swiperRef = useRef()

  function onSlideClick(el) {
    const classList = el.currentTarget.classList
    if (classList.contains("swiper-slide-next")) {
      swiperRef.current.swiper.slideNext()
    } else if (classList.contains("swiper-slide-prev")) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  return (
    <Wrapper>
      <TextWrapper>
        <Header>
          Segment your fanbase
          <br /> Understand their behaviors and engagement patterns
          <br /> Set and measure goals to track campaign performance
        </Header>
        <Body>
          Using Fanbank, you can understand your fanbase and identify what you
          need to know about them to increase their value.
        </Body>
      </TextWrapper>
      <SwiperWrapper>
        <Swiper
          ref={swiperRef}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={30}
          speed={1200}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
          }}
          loop
          loopedSlides={3}
          modules={[Pagination, A11y, Autoplay]}
        >
          <SwiperSlide tag="figure" onClick={onSlideClick}>
            <SlideImageWrapper>
              <StaticImage src={"../../assets/carousel1.item1.png"} />
            </SlideImageWrapper>
            <SlideText>
              <strong>{"Audience overviews"}</strong>{" "}
              {
                "See your entire fanbase, and discover fan segments that matter most to you."
              }
            </SlideText>
          </SwiperSlide>
          <SwiperSlide tag="figure" onClick={onSlideClick}>
            <SlideImageWrapper>
              <StaticImage src={"../../assets/carousel1.item2.png"} />
            </SlideImageWrapper>
            <SlideText>
              <strong>{"Fan activity"}</strong>{" "}
              {
                "Capture every interaction that your fans make with you—digitally and physically—and understand their value."
              }
            </SlideText>
          </SwiperSlide>
          <SwiperSlide tag="figure" onClick={onSlideClick}>
            <SlideImageWrapper>
              <StaticImage src={"../../assets/carousel1.item3.png"} />
            </SlideImageWrapper>
            <SlideText>
              <strong>{"Federated views"}</strong>{" "}
              {
                "Get a global view of your league or federation's entire fanbase, and easily compare audiences across your members."
              }
            </SlideText>
          </SwiperSlide>
        </Swiper>
      </SwiperWrapper>
    </Wrapper>
  )
}

const Header = styled.h3`
  font-size: 2rem;
  letter-spacing: -0.03em;
  font-weight: ${theme.weights.bold};
  line-height: 1.25;
`

const TextWrapper = styled.div`
  ${mainPadCenter};
`

const Wrapper = styled.div`
  margin-top: 40px;
`

const SwiperWrapper = styled.div`
  margin-top: 36px;

  .swiper-slide {
    width: auto;
    opacity: 0.2;
    transition: opacity 1.2s;
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    opacity: 1 !important;
  }

  .swiper-pagination {
    position: static;
    margin-top: 16px;
  }

  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-color: white;
`

const SlideImageWrapper = styled.div`
  width: calc(100vw - 2 * ${pad.desktop}px);
  max-width: 930px;
  border-radius: 10px;
  overflow: hidden;
  isolation: isolate;
`

const SlideText = styled.figcaption`
  width: calc(100vw - 2 * ${pad.desktop}px);
  font-weight: ${theme.weights.light};
  max-width: 930px;
  font-size: 1rem;
  line-height: 1;
  max-width: 610px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;

  strong {
    font-weight: ${theme.weights.black};
  }
`
