import React from "react"
import styled from "styled-components"
import { mainPad, mainPadCenter, mq } from "../../styles/mixins"
import { colors, weights } from "../theme"
import { StaticImage } from "gatsby-plugin-image"
import Trademark from "../trademark"

export default function Hero() {
  return (
    <Wrapper>
      <ImageWrapper>
        <StaticImage
          src="../../assets/bg.index.hero.png"
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
        />
      </ImageWrapper>
      <Headline>
        <strong>Organize, value, and trade</strong> your fan data with Pumpjack.
      </Headline>
      <Subhead>
        All delivered through our platform,{" "}
        <strong>
          Fanbank
          <Trademark />
        </strong>
      </Subhead>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 32px;

  ${mq(">lg")} {
    padding-top: 96px;
  }
`

const Headline = styled.p`
  position: relative;
  font-weight: ${weights.bold};
  font-size: 3rem;
  line-height: 1;
  letter-spacing: -0.0275em;

  ${mq(">lg")} {
    font-size: 4rem;
  }

  strong {
    font-weight: ${weights.black};
  }

  ${mainPadCenter}
`

const Subhead = styled.p`
  position: relative;
  font-weight: ${weights.medium};
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: ${colors.blue["20"]};

  margin-top: 16px;

  ${mq(">lg")} {
    margin-top: 32px;
  }

  strong {
    font-weight: ${weights.black};
  }

  ${mainPadCenter}
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.6;

  mix-blend-mode: hard-light;
`
