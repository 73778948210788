import { css } from "styled-components"

export const bp = {
  sm: 560,
  md: 768,
  lg: 1024,
  xl: 1440,
}

export function mq(arg = ">sm") {
  const breakpoint = arg.slice(1)
  if (arg[0] === ">") {
    return `@media (min-width: ${bp[breakpoint]}px)`
  }
  return `@media (max-width: ${bp[breakpoint] - 1}px)`
}

export const pad = {
  mobile: 30,
  desktop: 75,
}

export const mainPad = css`
  padding-left: ${pad.mobile}px;
  padding-right: ${pad.mobile}px;

  ${mq(">md")} {
    padding-left: ${pad.desktop}px;
    padding-right: ${pad.desktop}px;
  }
`

export const mainPadCenter = css`
  ${mainPad}
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
`
