import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { mainPadCenter, mq } from "../styles/mixins"
import theme from "./theme"

export default function Footer() {
  return (
    <Wrapper>
      <ImageWrapper>
        <StaticImage
          src="../assets/logo.png"
          alt="logo"
          placeholder="none"
          height={40}
        />
      </ImageWrapper>
      <Menus>
        <Menu
          title={"Company"}
          options={[
            { name: "About Pumpjack", path: "/about" },
            { name: "Press", path: "/news" },
            { name: "Careers", path: "/careers" },
          ]}
        />
        <Menu
          title={"Disclosures"}
          options={[
            { name: "Privacy Policies", path: "/privacy" },
            { name: "Anti-trafficking Statement", path: "/anti-slavery" },
          ]}
        />
      </Menus>
      <Copyright>
        ©2022 Pumpjack Dataworks. All rights reserved.{" "}
        <strong>Made on Earth</strong>
      </Copyright>
    </Wrapper>
  )
}

function Menu({ title, options }) {
  return (
    <MenuWrapper>
      <MenuHeader>{title}</MenuHeader>
      <MenuList>
        {options.map(option => (
          <li>
            <MenuListItem to={option.path}>{option.name}</MenuListItem>
          </li>
        ))}
      </MenuList>
    </MenuWrapper>
  )
}

const Wrapper = styled.footer`
  ${mainPadCenter};
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;
  color: ${theme.colors.cool["100"]};
`

const Menus = styled.div`
  ${mq(">sm")} {
    display: flex;
  }
  padding-bottom: 84px;
  width: 100%;
  border-bottom: 1px grey;
`

const MenuWrapper = styled.div`
  ${mq("<sm")} {
    margin-top: 32px;
  }
  ${mq(">sm")} {
    &:not(:first-of-type) {
      margin-left: 67px;
    }
  }
`

const MenuHeader = styled.h3`
  font-size: 0.875rem;
`

const MenuList = styled.ul`
  margin-top: 8px;
  font-size: 0.75rem;
`

const MenuListItem = styled(Link)`
  line-height: 2;
`

const ImageWrapper = styled.div`
  ${mq(">sm")} {
    position: absolute;
    right: 75px;
  }
`

const Copyright = styled.p`
  ${mq(">sm")} {
    text-align: right;
  }

  font-size: 0.625rem;
`
