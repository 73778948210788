export const colors = {
  white: "#ffffff",
  cool: {
    5: "rgba(250, 251, 252, 1)",
    10: "rgba(243, 244, 246, 1)",
    20: "rgba(216, 219, 225, 1)",
    30: "rgba(184, 189, 199, 1)",
    40: "rgba(153, 159, 172, 1)",
    50: "rgba(123, 131, 145, 1)",
    60: "rgba(99, 107, 121, 1)",
    70: "rgba(78, 85, 98, 1)",
    80: "rgba(59, 66, 78, 1)",
    90: "rgba(34, 39, 48, 1)",
    100: "rgba(21, 24, 30, 1)",
  },
  red: {
    5: "rgba(255, 248, 249, 1)",
    10: "rgba(255, 236, 239, 1)",
    20: "rgba(255, 209, 217, 1)",
    30: "rgba(255, 158, 166, 1)",
    40: "rgba(255, 87, 92, 1)",
    50: "rgba(232, 32, 59, 1)",
    60: "rgba(188, 0, 38, 1)",
    70: "rgba(153, 0, 36, 1)",
    80: "rgba(117, 0, 23, 1)",
    90: "rgba(66, 0, 13, 1)",
    100: "rgba(41, 0, 4, 1)",
  },
  orange: {
    5: "rgba(255, 251, 246, 1)",
    10: "rgba(255, 244, 224, 1)",
    20: "rgba(255, 218, 184, 1)",
    30: "rgba(255, 178, 120, 1)",
    40: "rgba(250, 133, 45, 1)",
    50: "rgba(209, 101, 0, 1)",
    60: "rgba(163, 79, 0, 1)",
    70: "rgba(133, 63, 0, 1)",
    80: "rgba(102, 51, 0, 1)",
    90: "rgba(61, 31, 0, 1)",
    100: "rgba(38, 15, 3, 1)",
  },
  yellow: {
    5: "rgba(254, 253, 236, 1)",
    10: "rgba(252, 249, 207, 1)",
    20: "rgba(250, 229, 152, 1)",
    30: "rgba(247, 201, 72, 1)",
    40: "rgba(230, 172, 0, 1)",
    50: "rgba(184, 138, 0, 1)",
    60: "rgba(140, 105, 0, 1)",
    70: "rgba(112, 84, 0, 1)",
    80: "rgba(87, 65, 0, 1)",
    90: "rgba(56, 41, 0, 1)",
    100: "rgba(33, 24, 0, 1)",
  },
  chartreuse: {
    5: "rgba(249, 254, 236, 1)",
    10: "rgba(243, 252, 204, 1)",
    20: "rgba(223, 245, 137, 1)",
    30: "rgba(186, 229, 46, 1)",
    40: "rgba(161, 201, 0, 1)",
    50: "rgba(133, 166, 0, 1)",
    60: "rgba(98, 128, 0, 1)",
    70: "rgba(76, 97, 0, 1)",
    80: "rgba(59, 77, 0, 1)",
    90: "rgba(36, 48, 0, 1)",
    100: "rgba(22, 28, 0, 1)",
  },
  green: {
    5: "rgba(240, 255, 240, 1)",
    10: "rgba(221, 254, 221, 1)",
    20: "rgba(173, 255, 174, 1)",
    30: "rgba(106, 236, 110, 1)",
    40: "rgba(23, 207, 36, 1)",
    50: "rgba(9, 170, 17, 1)",
    60: "rgba(0, 133, 6, 1)",
    70: "rgba(0, 102, 8, 1)",
    80: "rgba(0, 77, 6, 1)",
    90: "rgba(12, 44, 2, 1)",
    100: "rgba(2, 29, 4, 1)",
  },
  cyan: {
    5: "rgba(235, 255, 255, 1)",
    10: "rgba(211, 251, 253, 1)",
    20: "rgba(169, 244, 249, 1)",
    30: "rgba(105, 220, 240, 1)",
    40: "rgba(11, 189, 208, 1)",
    50: "rgba(0, 152, 168, 1)",
    60: "rgba(0, 122, 133, 1)",
    70: "rgba(0, 98, 107, 1)",
    80: "rgba(0, 73, 82, 1)",
    90: "rgba(0, 40, 46, 1)",
    100: "rgba(0, 24, 26, 1)",
  },
  blue: {
    5: "rgba(247, 250, 255, 1)",
    10: "rgba(224, 245, 255, 1)",
    20: "rgba(184, 229, 255, 1)",
    30: "rgba(117, 202, 255, 1)",
    40: "rgba(36, 175, 255, 1)",
    50: "rgba(0, 132, 224, 1)",
    60: "rgba(0, 107, 184, 1)",
    70: "rgba(0, 85, 148, 1)",
    80: "rgba(0, 63, 113, 1)",
    90: "rgba(0, 35, 66, 1)",
    100: "rgba(0, 19, 41, 1)",
  },
  indigo: {
    5: "rgba(249, 249, 255, 1)",
    10: "rgba(241, 239, 255, 1)",
    20: "rgba(221, 217, 255, 1)",
    30: "rgba(187, 179, 255, 1)",
    40: "rgba(143, 143, 255, 1)",
    50: "rgba(111, 92, 255, 1)",
    60: "rgba(94, 36, 255, 1)",
    70: "rgba(75, 5, 214, 1)",
    80: "rgba(54, 0, 163, 1)",
    90: "rgba(31, 8, 84, 1)",
    100: "rgba(19, 8, 48, 1)",
  },
  violet: {
    5: "rgba(253, 247, 255, 1)",
    10: "rgba(251, 236, 255, 1)",
    20: "rgba(246, 208, 255, 1)",
    30: "rgba(240, 163, 255, 1)",
    40: "rgba(232, 87, 255, 1)",
    50: "rgba(204, 0, 224, 1)",
    60: "rgba(164, 0, 182, 1)",
    70: "rgba(138, 0, 153, 1)",
    80: "rgba(106, 0, 117, 1)",
    90: "rgba(59, 0, 66, 1)",
    100: "rgba(32, 0, 37, 1)",
  },
  magenta: {
    5: "rgba(255, 247, 252, 1)",
    10: "rgba(255, 235, 246, 1)",
    20: "rgba(255, 207, 235, 1)",
    30: "rgba(255, 158, 219, 1)",
    40: "rgba(255, 82, 198, 1)",
    50: "rgba(219, 0, 168, 1)",
    60: "rgba(178, 0, 136, 1)",
    70: "rgba(148, 0, 113, 1)",
    80: "rgba(117, 0, 88, 1)",
    90: "rgba(66, 0, 48, 1)",
    100: "rgba(36, 0, 25, 1)",
  },
}

export const weights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
}

const theme = {
  colors,
  weights
}

export default theme
