import React, { useEffect, useReducer, useCallback } from "react"
import styled from "styled-components"
import useInputState from "../../util/useInputState"
import theme from "../theme"
import { mainPadCenter, mq } from "../../styles/mixins"

function ctaReducer(state, action) {
  switch (action.type) {
    case "submit":
      return { value: action.payload, isSubmitting: true }
    case "error":
      return { ...state, isSubmitting: false, error: true }
    case "success":
      return { ...state, isSubmitting: false, submitted: true }
    default:
      throw new Error()
  }
}

export default function Cta() {
  const [{ value, isSubmitting, submitted, error } = {}, dispatch] = useReducer(
    ctaReducer,
    {}
  )

  const [emailVal, onChangeEmail] = useInputState(value)
  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      dispatch({ type: "submit", payload: emailVal })
      if (process.env.NODE_ENV === "production") {
        typeof window !== "undefined" &&
          window.gtag("event", "submit", {
            event_category: "contact",
          })
      }
      async function sendEmail() {
        try {
          const result = await fetch(
            "https://pjapi.pumpjackdataworks.com/contact/contact",
            {
              method: "POST",
              body: JSON.stringify({
                emailAddress: emailVal,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )

          if (result.status === 200) {
            dispatch({ type: "success" })
          } else {
            dispatch({ type: "error" })
          }
        } catch (e) {
          dispatch({ type: "error" })
        }
      }
      sendEmail()
    },
    [emailVal, dispatch]
  )

  useEffect(() => {
    value && onChangeEmail({ target: { value } })
  }, [value, onChangeEmail])

  return (
    <Wrapper>
      <Card>
        <div>
          <Headline>Interested in Fanbank?</Headline>
          {/* <Subhead>Get in touch with our sales team.</Subhead> */}
        </div>{" "}
        {!submitted && !error && (
          <Form onSubmit={onSubmit}>
            <Input
              placeholder="Enter your email address"
              type="email"
              aria-label="Email address"
              autoComplete="email"
              value={emailVal}
              onChange={onChangeEmail}
              disabled={isSubmitting}
            />
            <Button type="submit" disabled={isSubmitting}>
              Contact Me
            </Button>
          </Form>
        )}
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${mainPadCenter};
`
const Card = styled.div`
  background: ${theme.colors.indigo["80"]};
  border-radius: 10px;
  max-width: 770px;
  margin-top: 32px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;

  ${mq(">md")} {
    display: flex;
    align-items: center;
    height: 64px;
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Headline = styled.p`
  font-size: 1rem;
  line-height: 1;
  font-weight: ${theme.weights.black};
`

const Subhead = styled.p`
  font-size: 0.875;
  line-height: 1.14;
  margin-top: 4px;
`

const Form = styled.form`
  margin-top: 8px;
  display: flex;

  ${mq(">md")} {
    margin-top: 0;
    padding-left: 30px;
    margin-left: auto;
    max-width: 430px;
    flex: 1;
  }
`

const Input = styled.input`
  align-items: center;
  margin-top: 4px;
  height: 32px;
  outline: none;
  border: 1px solid ${theme.colors.cool["20"]};
  background: ${theme.colors.white};
  color: ${theme.colors.cool["90"]};
  padding: 10px;
  display: block;
  border-radius: 5px;
  min-width: 0;
  font-size: 0.75rem;

  &::placeholder {
    color: ${theme.colors.cool["70"]};
  }

  margin: 0;
  flex: 1;
`

const Button = styled.button`
  white-space: nowrap;
  background: ${theme.colors.blue["50"]};
  display: block;
  height: 32px;
  outline: none;
  border: none;
  border-radius: 16px;
  color: white;
  font-weight: ${theme.weights.bold};
  padding: 0 16px;
  margin-left: 15px;
  font-size: 0.875rem;
`
