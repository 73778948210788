import { createGlobalStyle } from "styled-components"
import { colors } from "../components/theme"

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Inter";
    color: ${colors.white};
  }
`

export default GlobalStyle
