import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { mainPadCenter, mq } from "../../styles/mixins"
import { Body, Headline } from "./snapshot"

export default function Partners() {
  return (
    <Wrapper>
      <Headline>We power some of the leading brands in sport.</Headline>
      <Body style={{ maxWidth: 600 }}>
        From young leagues to the biggest names in sport, Pumpjack improves
        asset value, rights value, and revenue for all types of rights holders.
      </Body>
      <GridWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.ospreys.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.mavericks.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.hkru.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
        {/* <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.realmadrid.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper> */}
        <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.intermiami.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.atp.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.mlr.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../assets/partners/partner.wtt.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
      </GridWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${mainPadCenter};
`

const GridWrapper = styled.div`
  display: flex;

  margin-top: 48px;
  margin-bottom: 48px;

  flex-wrap: wrap;
  gap: 60px;

  ${mq(">lg")} {
    gap: 80px;
  }

  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.div`
  display: block;

  width: 30%;

  ${mq(">md")} {
    width: 20%;
    width: 130px;
  }
`
