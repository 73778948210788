import React from "react"
import styled from "styled-components"
import GlobalStyle from "../styles/global"
import ResetStyles from "../styles/reset"
import Footer from "./footer"
import Header from "./header"
import { colors } from "./theme"
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/900.css";

export default function Layout({ children }) {
  return (
    <div>
      <ResetStyles />
      <GlobalStyle />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </div>
  )
}

const Main = styled.main`
`
