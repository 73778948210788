import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import { mainPad, mainPadCenter, mq } from "../../styles/mixins"
import theme from "../theme"
import { Body, Headline, Subhead } from "./snapshot"

export default function Trade() {
  return (
    <Wrapper>
      <TextWrapper>
        <Subhead>Trade</Subhead>
        <Headline>
          Welcome to the first ever marketplace for sports and entertainment fan
          data.
        </Headline>
        <Body>
          Pumpjack enables access to data for commercial partners, which
          generates new revenue opportunities at scale between rights holders
          and fans.
        </Body>
      </TextWrapper>
      <ImageWrapper>
        <StaticImage
          src="../../assets/trade.diagram.png"
          placeholder="none"
          quality={100}
        />
      </ImageWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 48px;
  ${mainPadCenter};

  ${mq(">md")} {
    display: flex;
    align-items: center;
  }

  ${Body} {
    margin-bottom: 16px;
  }
`

const ImageWrapper = styled.div``

const TextWrapper = styled.div``
