import React from "react"
import styled from "styled-components"
import { mainPadCenter, mq } from "../../styles/mixins"
import theme from "../theme"
import Trademark from "../trademark"

export default function Cards() {
  return (
    <Wrapper>
      <Headline>
        <strong>
          Fanbank
          <Trademark />
        </strong>{" "}
        identifies the value of your data in 3 distinct ways.
      </Headline>
      <CardList>
        <Card
          index={1}
          title="Transactional Value"
          body="Fanbank™ calculates and reports to you the lifetime value of every fan in your database."
        />
        <Card
          index={2}
          title="Commercial Value"
          body="Fanbank™ crystallizes the commercial value of your fan database and lets you easily share with your partners and sponsors."
        />
        <Card
          index={3}
          title="Profile Value"
          body="Fanbank™ analyzes each data point to establish the balance sheet asset value of your fanbase."
        />
      </CardList>
    </Wrapper>
  )
}

function Card({ index, title, body }) {
  return (
    <CardWrapper>
      <CardIndex>{index}</CardIndex>
      <CardTitle>{title}</CardTitle>
      <CardBody>{body}</CardBody>
    </CardWrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 48px;
  position: relative;
`

const Headline = styled.h2`
  font-size: 1.9rem;
  letter-spacing: -0.02em;
  font-weight: ${theme.weights.bold};
  line-height: 1;

  ${mainPadCenter};

  strong {
    font-weight: ${theme.weights.black};
  }
`

const CardList = styled.ul`
  display: grid;
  gap: 16px;
  margin-top: 32px;

  ${mq(">sm")} {
    margin-top: 24px;
    gap: 30px;

    grid-template-columns: 1fr 1fr 1fr;
  }
  ${mainPadCenter};
`

const CardWrapper = styled.li`
  box-shadow: rgba(0, 19, 41, 0.5) 0 4px 8px;
  background: linear-gradient(${theme.colors.indigo["80"]}, ${theme.colors.indigo["90"]});
  );
  border-radius: 10px;
  padding: 16px;
  padding-bottom: 28px;
  min-height: 176px;
`

const CardIndex = styled.p`
  font-weight: ${theme.weights.black};
  color: ${theme.colors.blue["40"]};
  font-size: 1.25rem;
  line-height: 0.66;
`

const CardTitle = styled.h3`
  font-weight: ${theme.weights.bold};
  font-size: 1.25rem;
  line-height: 1.2;
  margin-top: 8px;
`

const CardBody = styled.p`
  font-weight: ${theme.weights.light};
  font-size: 1rem;
  line-height: 1.25;
  margin-top: 8px;
`
