import React from "react"
import styled from "styled-components"

const Tm = styled.span`
  font-size: 0.5em;
  margin-top: -30px;
  display: inline-block;
  transform: translate(0.1em, -0.75em);
`

export default function Trademark() {
  return <Tm>™</Tm>
}
