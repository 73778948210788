import React from "react"
import Layout from "../components/layout"
import SEO from "../components/archive/seo"
import Hero from "../components/index/hero"
import Cards from "../components/index/cards"
import Figure from "../components/index/figure"
import Cta from "../components/index/cta"
import Carousel from "../components/index/carousel"
import Snapshot from "../components/index/snapshot"
import Partners from "../components/index/partners"
import Trade from "../components/index/trade"
import styled from "styled-components"
import { colors } from "../components/theme"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Section>
      <Hero />
      <Figure />
      <Carousel />
    </Section>
    <Section>
      <Snapshot />
      <Cards />
      <Trade />
      <Cta />
    </Section>
    <Section>
      <Partners />
      <Cta />
    </Section>
  </Layout>
)

const Section = styled.section`
  background: linear-gradient(${colors.blue["100"]}, ${colors.indigo["90"]});
  &:not(:first-of-type) {
    padding-top: 48px;
  }
  padding-bottom: 48px;
`

export default IndexPage
