import React from "react"
import styled from "styled-components"
import { mainPad, mainPadCenter, mq } from "../styles/mixins"
import theme, { colors, weights } from "./theme"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const locations = [
  {
    name: "About",
    location: "/about",
  },
  {
    name: "Press",
    location: "/news",
  },
  {
    name: "Careers",
    location: "/careers",
  },
]

export default function Header() {
  return (
    <HeaderWrapper>
      <Inner>
        <LogoWrapper to="/">
          <StaticImage
            src="../assets/mark.png"
            alt="pumpjack logo"
            loading="eager"
            height={32}
            placeholder={"none"}
          />
        </LogoWrapper>
        <NavWrapper>
          {locations.map(location => (
            <li>
              <NavLink to={location.location}>{location.name}</NavLink>
            </li>
          ))}
        </NavWrapper>
      </Inner>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.header`
  background: ${colors.blue["100"]};
  position: sticky;
  top: 0;
  z-index: 100;
`

const Inner = styled.div`
  ${mainPadCenter}
  display: flex;
  align-items: center;
  height: 48px;
`

const LogoWrapper = styled(Link)`
  margin-right: auto;
`

const NavWrapper = styled.ul`
  display: flex;

  * + * {
    margin-left: 20px;
  }

  ${mq("<sm")} {
    display: none;
  }
`

const NavLink = styled(Link)`
  font-size: 14px;
  color: ${colors.white};
  font-weight: ${weights.regular};
`
